<template>
  <div class="overlay-con">
    <title-con title="溯源标签" />
    <map-con @regionchange="costPlannedsubChange" />
    <title-con style="margin-top: 106px" title="农业主体" />
    <div v-if="Object.keys(homePageTopVS)" class="main-subject">
      <div class="subject-newly">
        <img :src="require('@/assets/images/nyzt.png')" alt="" />
        <div class="newly-con">
          <div class="cont">
            <h1>{{ homePageTopVS.dayIncrease }}</h1>
            <h1>本月新增</h1>
          </div>
          <div class="cont">
            <h1>{{ homePageTopVS.monthIncrease }}</h1>
            <h1>本日新增</h1>
          </div>
        </div>
      </div>
      <div
        v-if="
          homePageTopVS.subjectTypeNum &&
          homePageTopVS.subjectTypeNum.length != 0
        "
        class="zhutis-con"
      >
        <img :src="require('@/assets/images/nyztb.png')" alt="" />
        <div class="grjt">
          <span>{{ homePageTopVS.subjectTypeNum[3].subjectNum }}</span>
        </div>
        <div class="jtnc">
          <span>{{ homePageTopVS.subjectTypeNum[2].subjectNum }}</span>
        </div>
        <div class="nyzts">
          <span>{{ homePageTopVS.subjectTypeNum[0].subjectNum }}</span>
        </div>
        <div class="qy">
          <span>{{ homePageTopVS.totalSubject }}</span
          ><span class="unit">家</span>
        </div>
        <div class="qt">
          <span>0</span>
        </div>
        <div class="hzs">
          <span>{{ homePageTopVS.subjectTypeNum[1].subjectNum }}</span>
        </div>
      </div>
      <div class="screening-con">
        <div class="row-con">
          <img :src="require('@/assets/images/ztsk.png')" alt="" />
          <div class="data-con">
            <h1>
              <countTo
                :startVal="startVal"
                :endVal="vertical.patrolNum"
                :duration="duration"
              >
              </countTo>
            </h1>
            <h1>督察巡检</h1>
          </div>
        </div>
        <div class="row-con">
          <img :src="require('@/assets/images/ztsk.png')" alt="" />
          <div class="data-con">
            <h1>
              <countTo
                :startVal="startVal"
                :endVal="vertical.checkNum"
                :duration="duration"
              >
              </countTo>
            </h1>
            <h1>产品检测</h1>
          </div>
        </div>
        <div class="row-con">
          <img :src="require('@/assets/images/ztsk.png')" alt="" />
          <div class="data-con">
            <h1>
              <countTo
                :startVal="startVal"
                :endVal="vertical.labelNum"
                :duration="duration"
              >
              </countTo>
            </h1>
            <h1>合格证打印</h1>
          </div>
        </div>
        <div class="row-con">
          <img :src="require('@/assets/images/ztsk.png')" alt="" />
          <div class="data-con">
            <h1>
              <countTo
                :startVal="startVal"
                :endVal="vertical.certificateNum"
                :duration="duration"
              >
              </countTo>
            </h1>
            <h1>合格证信息</h1>
          </div>
        </div>
      </div>
    </div>
    <title-con style="margin-top: 106px" title="打印信息" />
    <div class="label-info">
      <div class="mian-label">
        <div class="title-con">
          <span>日期</span>
          <span>产品名称</span>
          <span>打印数据</span>
          <span>主体名称</span>
        </div>
        <div v-if="labelList && labelList.length != 0" class="news-list">
          <swiper
            :options="recSwiperOptionla"
            :not-next-tick="notNextTickOfRecSwiperdyla"
            ref="recSwiperhz"
            class="swiper-item"
          >
            <swiper-slide v-for="(item, idx) in labelList" :key="idx">
              <div class="info-row">
                <div class="row-con">
                  <span>{{ item.createTime }}</span>
                  <span>{{ item.productName }}</span>
                  <span>{{ item.printSum }}</span>
                  <span>{{ item.subjectName }}</span>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="empty-con" v-if="labelList.length == 0">
          <a-empty :image="require('@/assets/images/wusj.png')" />
        </div>
      </div>
    </div>
    <title-con style="margin-top: 106px" title="类别占比" />
    <div class="category-proportion">
      <img class="bg" :src="require('@/assets/images/leibeibg.png')" alt="" />
      <div v-if="dashboardList && dashboardList.length != 0" class="left">
        <div v-if="dashboardList[7]" class="productName-con">
          <img
            v-if="dashboardList[7].check"
            class="nimg"
            :src="require('@/assets/images/leftxz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[7].check"
            class="nimg"
            :src="require('@/assets/images/leftnot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[7].check
                ? 'productName-l selected'
                : 'productName-l notSelected'
            "
          >
            <span>{{ dashboardList[7].value }}</span>
            <span>{{ dashboardList[7].name }}</span>
          </div>
        </div>
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="lefto">
        <div v-if="dashboardList[6]" class="productName-con">
          <img
            v-if="dashboardList[6].check"
            class="nimg"
            :src="require('@/assets/images/leftxz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[6].check"
            class="nimg"
            :src="require('@/assets/images/leftnot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[6].check
                ? 'productName-l selected'
                : 'productName-l notSelected'
            "
          >
            <span>{{ dashboardList[6].value }}</span>
            <span>{{ dashboardList[6].name }}</span>
          </div>
        </div>
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="leftt">
        <div v-if="dashboardList[5]" class="productName-con">
          <img
            v-if="dashboardList[5].check"
            class="nimg"
            :src="require('@/assets/images/leftxz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[5].check"
            class="nimg"
            :src="require('@/assets/images/leftnot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[5].check
                ? 'productName-l selected'
                : 'productName-l notSelected'
            "
          >
            <span>{{ dashboardList[5].value }}</span>
            <span>{{ dashboardList[5].name }}</span>
          </div>
        </div>
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="lefts">
        <div v-if="dashboardList[4]" class="productName-con">
          <img
            v-if="dashboardList[4].check"
            class="nimg"
            :src="require('@/assets/images/leftxz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[4].check"
            class="nimg"
            :src="require('@/assets/images/leftnot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[4].check
                ? 'productName-l selected'
                : 'productName-l notSelected'
            "
          >
            <span>{{ dashboardList[4].value }}</span>
            <span>{{ dashboardList[4].name }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="dashboardList && dashboardList.length != 0"
        class="category-echarts"
      >
        <div class="pie-con" id="pie"></div>
      </div>
      <div class="empty-con" v-if="dashboardList.length == 0">
        <a-empty :image="require('@/assets/images/wusj.png')" />
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="ri">
        <div v-if="dashboardList[0]" class="productName-con">
          <img
            v-if="dashboardList[0].check"
            class="nimg"
            :src="require('@/assets/images/rixz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[0].check"
            class="nimg"
            :src="require('@/assets/images/rinot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[0].check
                ? 'productName-r selected'
                : 'productName-r notSelected'
            "
          >
            <span>{{ dashboardList[0].value }}</span>
            <span>{{ dashboardList[0].name }}</span>
          </div>
        </div>
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="rio">
        <div v-if="dashboardList[1]" class="productName-con">
          <img
            v-if="dashboardList[1].check"
            class="nimg"
            :src="require('@/assets/images/rixz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[1].check"
            class="nimg"
            :src="require('@/assets/images/rinot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[1].check
                ? 'productName-r selected'
                : 'productName-r notSelected'
            "
          >
            <span>{{ dashboardList[1].value }}</span>
            <span>{{ dashboardList[1].name }}</span>
          </div>
        </div>
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="rit">
        <div v-if="dashboardList[2]" class="productName-con">
          <img
            v-if="dashboardList[2].check"
            class="nimg"
            :src="require('@/assets/images/rixz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[2].check"
            class="nimg"
            :src="require('@/assets/images/rinot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[2].check
                ? 'productName-r selected'
                : 'productName-r notSelected'
            "
          >
            <span>{{ dashboardList[2].value }}</span>
            <span>{{ dashboardList[2].name }}</span>
          </div>
        </div>
      </div>
      <div v-if="dashboardList && dashboardList.length != 0" class="ris">
        <div v-if="dashboardList[3]" class="productName-con">
          <img
            v-if="dashboardList[3].check"
            class="nimg"
            :src="require('@/assets/images/rixz.png')"
            alt=""
          />
          <img
            v-if="!dashboardList[3].check"
            class="nimg"
            :src="require('@/assets/images/rinot.png')"
            alt=""
          />
          <div
            :class="
              dashboardList[3].check
                ? 'productName-r selected'
                : 'productName-r notSelected'
            "
          >
            <span>{{ dashboardList[3].value }}</span>
            <span>{{ dashboardList[3].name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Index from "./index";
export default Index;
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
