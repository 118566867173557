<template>
  <div class="top-con">
    <!-- <img class="module" :src="require('@/assets/images/module.png')" alt="" /> -->
    <div class="title-top">
      <img :src="src" alt="" />
      <span>{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: "",
    src: "",
  },
};
</script>

<style lang="scss" scoped>
.top-con {
  width: 100%;
  position: relative;
  .module {
    width: 420px;
    // width: 100%;
    height: 63px;
  }
  .title-top {
    position: absolute;
    top: 18px;
    left: 31px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 18px;
      height: 19px;
      margin-right: 30px;
    }
    span {
      font-size: 18px;
      font-family: FZZhengHeiS-EB-GB;
      font-weight: 600;
      font-style: italic;
      color: #ffffff;
      line-height: 24px;
      width: 181px;
      height: 19px;
    }
  }
}
</style>
