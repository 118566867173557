import BYMixins from "@/mixins/index/index.js";
import { Progress, Tooltip, message, badge } from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import module from "@/components/module/index.vue";
import * as echarts from "echarts";
import "echarts-gl"; //echarts 3D插件
import overlayApi from "@/request/api/overlay.js";
import loginApi from "@/request/api/user.js";
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import titleCon from "@/components/title-con/index";
import mapCon from "@/components/map-con/index";
import countTo from "vue-count-to";
// import { heatmapData } from "./heatdata";
export default {
  name: `Overlay`,
  data() {
    return {
      regionCode: null,
      regionLevel: "",
      regionName: "",
      region: "",
      labelList: [],
      size: "default",
      recSwiperOptionla: {
        initialSlide: 0,
        slidesPerView: 10,
        direction: "vertical",
        loop: false,
        speed: 1500,
        autoplay: { disableOnInteraction: false, delay: 300 },
        observer: true,
        observeParents: true,
      },
      notNextTickOfRecSwiperdyla: true,
      homePageTopVS: {},
      vertical: {
        patrolNum: 0,
        checkNum: 0,
        labelNum: 0,
        certificateNum: 0,
      },
      // 需要滚动的时间
      duration: 5000,
      // 初始值
      startVal: 0,
      dashboardList: [],
    };
  },

  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
    "a-badge": badge,
    titleCon,
    mapCon,
    countTo,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mixins: [],
  mounted() {
    this.$eventBus.$emit("changeNav", { index: 0, name: "Overlay" });
    try {
      this.region = this.userInfo.region;
      this.regionLevel = this.userInfo.regionLevel;
    } catch (error) {
      console.log(error);
    }
    this.getLabelInfo();
    this.getSubInfo();
    this.getprintCount();
  },
  methods: {
    async dictionary() {
      await Promise.all([this.getInfo()]);
    },

    //获取标签信息
    async getLabelInfo() {
      let params = {
        region: this.region,
        regionLevel: this.regionLevel,
      };
      try {
        let res = await overlayApi.labelInformation(params);
        this.labelList = res.data;
      } catch (error) {
        console.log(error);
      }
    },

    async getSubInfo() {
      let params = {};
      if (this.regionLevel == 2) {
        params.cityCode = this.region;
      }
      if (this.regionLevel == 3) {
        params.districtCode = this.region;
      }
      if (this.regionLevel == 4) {
        params.townCode = this.region;
      }
      if (this.regionLevel == 5) {
        params.streetCode = this.region;
      }
      try {
        let res = await overlayApi.homePageTopVS(params);
        this.homePageTopVS = res.data;
      } catch (error) {
        console.log(error);
      }
      try {
        let res = await overlayApi.verticalScreen(params);
        if (this.region == "500000000000" || this.region == "500242000000") {
          // res.data.certificateNum = res.data.certificateNum + 207;
          res.data.labelNum = res.data.labelNum + 7139;
        }
        this.vertical = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    //获取部分产品合格证打印数
    async getprintCount() {
      let query = {
        region: this.region,
        regionLevel: this.regionLevel,
      };
      try {
        let response = await overlayApi.printCount(query);
        let list = response.data.slice(0, 8);
        let total = 0;
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];
          total += element.printCount;
        }
        let dashboardList = list.map((item) => {
          let percent = Number(((item.printCount / total) * 100).toFixed(2));
          return {
            name: item.productName,
            value: percent,
          };
        });
        for (let index = 0; index < dashboardList.length; index++) {
          let element = dashboardList[index];
          this.$set(element, "check", false);
        }
        if (dashboardList.length != 0) {
          this.dashboardList = dashboardList;
          await this.$nextTick();
          this.loadFastChart();
        }
      } catch (error) {
        console.log(error);
      }
    },
    loadFastChart() {
      let _this = this;
      let index = 0;
      var chartDom = document.getElementById("pie");
      var myChart = echarts.init(chartDom);
      let length = this.dashboardList.length - 1;
      // var trafficWay = this.dashboardList;
      // console.log("trafficWay", trafficWay);
      let option = {
        tooltip: {
          trigger: "item",
          show: false,
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },
        color: [
          "#1F84E9",
          "#1F84E9",
          "#1F84E9",
          "#1F84E9",
          "#1F84E9",
          "#1F84E9",
          "#1F84E9",
          "#1F84E9",
        ],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "50%"],
            // avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 6,
              // borderColor: "#fff",
              // borderWidth: 4,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 26,
                // fontWeight: "bold",
                color: "#EA9D23",
                background: "rgba(252,141,63,0.10)",
              },
              itemStyle: {
                color: "#EA9D23",
                background: "rgba(252,141,63,0.10)",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.dashboardList,
          },
        ],
      };
      myChart.setOption(option);

      function highlightNextFaPoint() {
        myChart.dispatchAction({
          type: "hideTip",
          seriesIndex: 0,
          dataIndex: index,
        });
        // 显示提示框
        myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: index,
        });
        if (_this.dashboardList.length != 0) {
          _this.dashboardList.forEach((element) => {
            element.check = false;
          });
          _this.dashboardList[index].check = true;
        }
        // 取消高亮指定的数据图形
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index == 0 ? length : index - 1,
        });

        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: index,
        });
        index++;
        if (index > length) {
          index = 0;
        }
      }
      if (_this.anquankuaisujianc != 0) {
        clearInterval(_this.anquankuaisujianc);
      }
      // 设置定时器来循环高亮点
      _this.anquankuaisujianc = setInterval(highlightNextFaPoint, 2000);

      //鼠标移入
      myChart.on("mouseover", (e) => {
        console.log("鼠标移入", e);
        console.log(option.series);
        // 取消高亮指定的数据图形
        myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: index == 0 ? 7 : index - 1,
        });
        this.dashboardList.forEach((element) => {
          element.check = false;
        });
        this.dashboardList[e.dataIndex].check = true;
        clearInterval(_this.anquankuaisujianc);
      });
      //鼠标移出
      myChart.on("globalout", (e) => {
        console.log(e);
        this.dashboardList.forEach((element) => {
          element.check = false;
        });
        clearInterval(this.anquankuaisujianc);
        this.anquankuaisujianc = setInterval(highlightNextFaPoint, 2000);
      });
    },
    costPlannedsubChange(value) {
      console.log(value);
      this.dashboardList = [];
      this.region = value.region;
      this.regionLevel = value.regionLevel;
      this.getLabelInfo();
      this.getSubInfo();
      this.getprintCount();
    },
  },
};
